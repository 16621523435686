.wrapper {
  font-kerning: none;
  perspective: 60rem;
  transform: translateY(2.4rem);
  transition: transform 0.6s ease-out;

  &.isTriggered {
    transform: translateY(0);
  }
}

.line {
  transform: translate3d(0, 0, 0);
  transform-origin: 40% 60%;
  opacity: 0;

  &.isTriggered {
    animation: titleLineAnimation 0.6s ease-out forwards;
  }
}

.nowrap {
  white-space: nowrap;
}

@keyframes titleLineAnimation {
  0% {
    transform: rotateX(-140deg) rotateY(14deg) rotateZ(-2deg) scale(0.9);
    opacity: 0;
  }
  55% {
    transform: rotateX(-16deg) rotateY(2deg) rotateZ(-1.2deg) scale(0.98);
    opacity: 0.9;
  }
  100% {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale(1);
    opacity: 1;
  }
}
